import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { logNetRequest, scrollToTop } from '../utils/usefulFuncs';
import { AI_TOOLS, CURRENT_DEFAULT_MODEL, docTypes } from '../constants';
import { createTimeInstance, formatLocalizedDateTime } from '../libs/nvstr-utils.es';
import { usePromptSeriesSubmit } from '../hooks/features/usePromptSeries';
import { Body1, Body5, Container, Page } from '../libs/nvstr-common-ui.es';
import { applicationActionTypes } from '../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { AdvancedSettingsFields, useAdvancedSettings } from '../hooks/application/useAdvancedSettings';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { addUrlParam } from '../utils';
import { handle400Statuses, sendApiRequest } from '../services/api';
import { Working } from '../components/UI/Working';
import { AdvancedSettingsButton } from '../containers/DigitalAnalyst/AdvancedSettings';
import { Answer } from '../containers/DigitalAnalyst/components/AnswerLine';
import { useNewCompanySnapshotStreamingResult } from '../hooks/features/useNewCompanySnapshotStreamingResult';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
    -webkit-appearance: auto !important;
  }
`;

const advancedSettingsFields = [
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.maxAnswerLength,
  AdvancedSettingsFields.maxContextChunks,
  AdvancedSettingsFields.smoothingToggle,
];

const defaultAdvancedSettings = {
  model: CURRENT_DEFAULT_MODEL,
  enableSmoothing: false,
  maxAnswerLength: '4096',
};

const minMarketCap = 50 * 1000 * 1000 * 1000; // 5 billion

const macroPrompts = {
  MACRO_QUOTES: 'Macro Quotes',
  ENERGY_TRANSITION: 'Energy Transition',
  INTEREST_RATES: 'Interest Rates',
  INFLATION: 'Inflation',
  DEMAND: 'Demand',
  SUPPLY_CHAIN: 'Supply Chain',
  CONSUMER: 'Consumer',
  CHINA: 'China',
  FX: 'FX',
  CRE: 'CRE',
};

const promptLookup = {
  [macroPrompts.CHINA]: [
    [
      macroPrompts.CHINA,
      'List companies that noted weakness in China, what they said, include quotes and context, organize by sector',
    ],
  ],
  [macroPrompts.SUPPLY_CHAIN]: [
    [
      macroPrompts.SUPPLY_CHAIN,
      'List companies that have mentioned issues with their supply chain, highlight what they said, including context, organize by sector',
    ],
  ],
  [macroPrompts.INTEREST_RATES]: [
    [
      macroPrompts.INTEREST_RATES,
      'List companies that have mentioned the negative impact of high interest rates on their business, and what they have said about its impact, include quotes and context, organize by sector',
    ],
  ],
  [macroPrompts.CRE]: [
    [
      macroPrompts.CRE,
      'Which companies have been hurt by weakness in commercial real estate, detail any impact they have mentioned, include quotes, organize by sector',
    ],
  ],
  [macroPrompts.FX]: [
    [
      macroPrompts.FX,
      'List companies that have been hurt by a strong US dollar and how it has impacted them, organize by sector',
    ],
  ],
  [macroPrompts.CONSUMER]: [
    [
      macroPrompts.CONSUMER,
      'List companies that have noted a change in consumer behavior or demand, what they said, including quotes and context, organize by sector',
    ],
  ],
  [macroPrompts.INFLATION]: [
    [
      macroPrompts.INFLATION,
      'List companies that have mentioned an impact on business from inflation, detail what they said, include quotes and context, organize by sector',
    ],
  ],
  [macroPrompts.ENERGY_TRANSITION]: [
    [
      macroPrompts.ENERGY_TRANSITION,
      'List companies that have mentioned an impact, or opportunity, positive or negative, from the transition to clean energy, detail what they said, organize by sector',
    ],
  ],
  [macroPrompts.MACRO_QUOTES]: [
    [
      macroPrompts.MACRO_QUOTES,
      'Which companies mentioned a negative impact from the macro environment, detail what they said, organize by sector',
    ],
  ],
  [macroPrompts.DEMAND]: [
    [
      'Strong Demand',
      'Which companies are seeing strong demand, list them and detail what they said, organize by sector',
    ],
    [
      'Weak Demand',
      'List companies that mentioned weak demand, what they said, include quotes and context, organize by sector',
    ],
  ],
};

const availableSignals = Object.values(macroPrompts);

const ResultItem = ({ data, selectedDocs, model }) => {
  const { displayText, resultId } = data;

  const { answersLookup, error: streamingError } = useNewCompanySnapshotStreamingResult(resultId);

  if (streamingError) {
    return (
      <Container top={24}>
        <Body5 bold>Something went wrong. Try submitting again.</Body5>
      </Container>
    );
  }

  const result = answersLookup ? answersLookup[0] : null;
  return (
    <Container top={48}>
      <Container>
        <Body5 bold>{displayText}</Body5>
      </Container>
      <Container top={16}>
        {!result ? <Working /> : <Answer result={result} selectedDocs={selectedDocs} model={model} enableMoreDetail />}
      </Container>
    </Container>
  );
};

function Results({ results, selectedDocs, model }) {
  if (!results) return null;
  return results.map((r) => <ResultItem key={r.resultId} data={r} selectedDocs={selectedDocs} model={model} />);
}

const tool = AI_TOOLS.MACRO_ECONOMIC_SIGNALS;

function MacroEconomicSignals() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { model } = useAdvancedSettings();

  const [docIds, setDocIds] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const [selectedSignal, setSelectedSignal] = React.useState('');
  const [isGettingResults, setIsGettingResults] = React.useState(false);
  const [results, setResults] = React.useState(null);

  const onSubmit = usePromptSeriesSubmit(tool);

  const onGoBack = () => navigate(ROUTES.home);

  const getStockUniverseForMacro = async () => {
    const docType = docTypes.EARNINGS;
    const startDate = createTimeInstance().subtract(1, 'months');
    const startDateAPI = formatLocalizedDateTime('-L', startDate);
    let URL = `v1/genai_tickers_filenames`;
    let URLParams = '';
    if (startDate) {
      URLParams = addUrlParam(URLParams, `start_date=${startDateAPI}`);
    }
    if (minMarketCap) {
      URLParams = addUrlParam(URLParams, `market_cap_min=${minMarketCap}`);
    }
    URLParams = addUrlParam(URLParams, `doc_types=${docType}`);
    URLParams = addUrlParam(URLParams, `topic=company`);
    URL += URLParams;

    const { status, data } = await sendApiRequest('get', URL);
    logNetRequest(status, data);
    handle400Statuses(status);
    if (status === 200) {
      const parseResponseToDocIdsList = (data) => {
        if (!data) {
          return null;
        }

        const docIds = [];
        const tickerDocs = Object.values(data);
        tickerDocs.forEach((docs) => {
          docs.forEach((doc) => {
            docIds.push(doc.id);
          });
        });
        return docIds;
      };

      return parseResponseToDocIdsList(data);
    } else {
      setError('Something went wrong, please try again later.');
      return null;
    }
  };

  React.useEffect(() => {
    scrollToTop();

    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });

    const init = async () => {
      const docIds = await getStockUniverseForMacro();
      if (docIds) {
        setDocIds(docIds);
        setIsLoading(false);
      }
    };

    init();
  }, []);

  const fetchSignals = async (signal) => {
    const prompts = promptLookup[signal];
    if (!prompts) return;

    setIsGettingResults(true);

    const getter = async (p) => {
      const [display, prompt] = p;
      const data = {
        docIds,
        prompt: prompt,
      };
      const { resultId, error } = await onSubmit(data);
      if (!error) {
        return { displayText: display, prompt, resultId };
      } else {
        return { error: 'Something went wrong.' };
      }
    };

    const requests = prompts.map((p) => getter(p));
    const nextResults = await Promise.all(requests);
    setResults(nextResults);
    setIsGettingResults(false);
  };

  const onMacroSelectionChange = (e) => {
    const value = e.target.value;
    setSelectedSignal(value);
    fetchSignals(value);
  };

  if (isLoading || error) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page width={'800px'}>
            <BackArrowButton onClick={onGoBack} />
            {isLoading ? (
              <Working />
            ) : (
              <Container top={38}>
                <Body5>Something went wrong, please refresh the page.</Body5>
              </Container>
            )}
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page width={results ? '1200px' : '800px'}>
          <AdvancedSettingsButton fields={advancedSettingsFields} />

          <BackArrowButton onClick={onGoBack} />

          <Container top={38}>
            <Body1 bold>Macro Economic Signals</Body1>
            <Container top={8}>
              <Body5>Select a signal from the list below:</Body5>
            </Container>
          </Container>
          <Container top={24}>
            <select value={selectedSignal} onChange={onMacroSelectionChange}>
              <option value={''}>Select one...</option>
              {availableSignals.map((s) => (
                <option value={s}>{s}</option>
              ))}
            </select>
          </Container>
          {isGettingResults ? <Working /> : null}
          {results !== null ? (
            <Results results={results} selectedDocs={docIds} model={model}></Results>
          ) : (
            <Container top={24}></Container>
          )}
        </Page>
      </Body>
    </PageWrapper>
  );
}

export default MacroEconomicSignals;
