import { createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import { parseResponseData } from './useGPTEmbeddingDocs';
import React from 'react';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { docsActionTypes } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../user/useCurrentUser';
import { addUrlParam } from '../../utils';

const filterApiDocLookupForLatestDocs = (lookup, params) => {
  const { symbols } = params;

  const recentDocs = [];
  const recentDocsGroupedByTickerArray = Object.values(lookup);
  if (recentDocsGroupedByTickerArray.length === 0) {
    return [];
  }
  recentDocsGroupedByTickerArray.forEach((g) => {
    g.forEach((d) => recentDocs.push(d));
  });

  const filteredDocs = recentDocs.filter((d) => {
    if (d.added_by_user_id !== null) {
      return false;
    }

    return true;
  });
  return filteredDocs;
};

const parseAndSortRecentDocs = (recentDocs) => {
  const recentDocsByDay = {};
  recentDocs.forEach((d) => {
    const { published_at: docDateString } = d;
    const reportDate = createTimeInstance(docDateString);
    const date = formatLocalizedDateTime('L', reportDate);
    if (recentDocsByDay[date]) {
      recentDocsByDay[date].push(d);
    } else {
      recentDocsByDay[date] = [d];
    }
  });

  const recentDocsBySecurity = {};
  recentDocs.forEach((d) => {
    const { security_id: sId } = d;
    if (recentDocsBySecurity[sId]) {
      recentDocsBySecurity[sId].push(d);
    } else {
      recentDocsBySecurity[sId] = [d];
    }
  });

  const days = Object.keys(recentDocsByDay);
  const sortedDays = days.sort((a, b) => (createTimeInstance(a).isAfter(createTimeInstance(b)) ? -1 : 1));
  return [sortedDays, recentDocsByDay];
};

export const useLatestEarningsDocs = () => {
  const dispatch = useDispatch();
  const currentUserId = useCurrentUser().id;
  const [isLoading, setIsLoading] = React.useState(true);

  const [latestDocs, setLatestDocs] = React.useState(null);
  const [latestDocDays, setLatestDocDays] = React.useState([]);
  const [latestDocsByDayLookup, setLatestDocsByDayLookup] = React.useState({});

  const getTickerDocData = async (params) => {
    const { symbols, marketCap, startDate: _params_startDate } = params;
    setIsLoading(true);

    const minMarketCap = marketCap;
    const startDate = _params_startDate;
    const dateFormat = '-L';
    const startDateApi = formatLocalizedDateTime(dateFormat, startDate);
    let URL = `v1/genai_tickers_filenames`;
    let URLParams = '?topic=company';
    if (startDate) {
      URLParams = addUrlParam(URLParams, `start_date=${startDateApi}`);
    }
    if (minMarketCap) {
      URLParams = addUrlParam(URLParams, `market_cap_min=${minMarketCap}`);
    }
    if (symbols) {
      URLParams = addUrlParam(URLParams, `tickers=${symbols}`);
    }
    URL += URLParams;

    const { status, data } = await sendApiRequest('get', URL);
    handle400Statuses(status);
    if (status === 200) {
      const { tickerFiles, docLookupById } = parseResponseData(data, currentUserId);
      dispatch({
        type: docsActionTypes.addDocs,
        payload: { tickerFiles, docLookupById },
      });

      if (symbols) {
        const filteredLookup = {};
        symbols.forEach((s) => {
          filteredLookup[s] = tickerFiles[s];
        });
        const docList = filterApiDocLookupForLatestDocs(filteredLookup, params || {});
        setLatestDocs(docList);
        setIsLoading(false);

        return { docsLookupByTicker: filteredLookup };
      } else {
        const docList = filterApiDocLookupForLatestDocs(tickerFiles, params || {});
        setLatestDocs(docList);
        setIsLoading(false);
      }
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    if (latestDocs) {
      const [sortedDays, recentDocsByDay] = parseAndSortRecentDocs(latestDocs);
      setLatestDocDays(sortedDays);
      setLatestDocsByDayLookup(recentDocsByDay);
    }
  }, [latestDocs]);

  return {
    latestDocDays,
    latestDocsByDayLookup,

    isLoading,
    getTickerDocData,
  };
};
