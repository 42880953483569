import { tornadoColorPalette } from '../libs/nvstr-utils.es';

export const RECAPTCHA_SITEKEY = '6LfWCoQaAAAAAPsJ5kDsGlaB4SWesCg0MajGhEkT';
export const DEV_RECAPTCHA_SITEKEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const getRecaptchaKey = () => {
  if (['localhost:3003', 'localhost:3001', 'localhost:3000'].includes(window.location.host)) {
    return DEV_RECAPTCHA_SITEKEY;
  }

  return RECAPTCHA_SITEKEY;
};

export const HAWKISH_COLOR = tornadoColorPalette.secondary.red;
export const DOVISH_COLOR = tornadoColorPalette.secondary.brightGreen;

export const alpha = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const docTypes = {
  '6K': '6-K',
  '8K': '8-K',
  '10K': '10-K',
  '10Q': '10-Q',
  '20F': '20-F',

  EARNINGS: 'Earnings Call Transcript',
  USER_EARNINGS: 'Uploaded Earnings Call Transcript',
  CONFERENCE_TRANSCRIPTS: 'Conference Transcript',
  LENDER_CALL: 'Lender Call',

  MELIUS_COMPANY_REPORT: 'Melius Company Report',
  MELIUS_INDUSTRY_REPORT: 'Melius Industry Report',
  MELIUS_WEEKLY_VIDEO_SLIDES: 'Melius Weekly Video Slides',
  MELIUS_INDUSTRIAL_STRATEGY: 'Melius Industrial Strategy',
};

export const earningsDocTypes = [docTypes.EARNINGS, docTypes.USER_EARNINGS, docTypes.CONFERENCE_TRANSCRIPTS];

export const DOC_SELECT_MODES = {
  docType: 'docType',
  speaker: 'speaker',
  companyTag: 'companyTag',
  ticker: 'ticker',
};

export const FOLLOW_UP_QUESTION_IDENTIFIER = '**Follow Up**';

export const PERMISSION_TYPES = {
  MELIUS_COMPANY_REPORT: 'Melius Company Report',
  MELIUS_INDUSTRY_REPORT: 'Melius Industry Report',
  MELIUS_WEEKLY_VIDEO_SLIDES: 'Melius Weekly Video Slides',
  MELIUS_INDUSTRIAL_STRATEGY: 'Melius Industrial Strategy',
};

export const VIEWABLE_DOC_TYPES = [docTypes.EARNINGS];

export const gptModels = {
  GPT_3_5_TURBO_0125: 'gpt-3.5-turbo-0125',
  GPT_3_5_TURBO_1106: 'gpt-3.5-turbo-1106',

  GPT_4: 'gpt-4',
  GPT_4_PREVIEW_1106: 'gpt-4-1106-preview',
  GPT_4_0125_PREVIEW: 'gpt-4-0125-preview',

  GPT_4_TURBO_2024_04_09: 'gpt-4-turbo-2024-04-09',
  GPT_4O_TURBO_2024_05_13: 'gpt-4o-2024-05-13',

  GPT_4o_mini_2024_07_18: 'gpt-4o-mini-2024-07-18',
  GPT_4o_2024_08_06: 'gpt-4o-2024-08-06',

  GPT_3_5_turbo: 'gpt-3.5-turbo',
  GPT_3_5_turbo_16k: 'gpt-3.5-turbo-16k',
};
export const CURRENT_DEFAULT_MODEL = gptModels.GPT_4o_2024_08_06;

export const AI_TOOLS = {
  RECENT_COMPANY_DOCS: 'recent_docs',
  EARNINGS_CALL_ANALYSIS: 'earnings_calls',
  LENDER_EARNINGS_CALL_ANALYSIS: 'lender_earnings_calls',
  COMPANY_SNAPSHOT: 'snapshot',
  CUSTOM_SNAPSHOT: 'custom-snapshot',
  WARNING_INDICATORS: 'warning_indicators',
  BULL_BEAR_CASES: 'bull_bear_cases',
  SCREENING: 'screen',
  RESEARCH_SCREENING: 'research_screen',
  WEBSITE_DOCS: 'website_query',
  QUERY: 'query',
  QUERY_RESEARCH_DOCS: 'research_query',
  CREDIT_AGREEMENT_ANALYSIS: 'credit_agreement_analysis',
  LIQUIDITY_TERMS_GRID_MODE: 'liquidity_terms_grid_mode',
  GENERALIZED_GRID_MODE: 'generalized_grid_mode',
  FED_LATEST_DOCS: 'fed_latest_docs',
  FED_QUERY: 'fed_query',
  UPLOAD_SOURCE_DOC: 'upload',
  TEXT_UPLOAD: 'upload',
  WEBPAGE_UPLOAD: 'web_crawl_upload',
  FED_UPLOAD: 'fed_upload',
  EXCEL_UPLOAD: 'excel_upload',
  MS_DOC_UPLOAD: 'ms_doc_upload',
  PPT_UPLOAD: 'ppt_upload',
  DOC_MGMT: 'dm',
  EVAL_TEMPLATE: 'eval_template',
  FED_PUBLIC_RELEASE: 'fed_public_release',
  FED_PUBLIC_SENTIMENT: 'fed_public_sentiment',
  MACRO_ECONOMIC_SIGNALS: 'macro_economic_signals',
  ECONOMIC_IMPACT: 'economic_impact',
  FACTOR: 'factor',
  SPEAKER_TAGGING: 'speaker_tagging',
  COMPANY_TAGGING: 'company_tagging',
  TEXT_SEARCH: 'text_search',
  CITATIONS: 'citations',
  COMPLIANCE: 'compliance',
  FED_GPT: 'fed_custom_client',
  WATCHLIST: 'watchlist',
};

export const MANUAL_DOCUMENT_SELECTION_OPTION = 'Select Documents Manually';
export const FED_DOC_TYPE_MODES = [
  'Federal Reserve Board (Fed) Release',
  'Federal Reserve Board (Fed) Testimony',
  'Federal Reserve Board (Fed) Speech',
  'Federal Reserve Board (Fed) FOMC Press Conference',
  'Federal Reserve Board (Fed) FOMC Meeting Transcript',
  'Federal Reserve Board (Fed) FOMC Minutes',
  'Federal Reserve Board (Fed) FOMC Statement',
  'Federal Reserve Board (Fed) FOMC Implementation Note',
  'Federal Reserve Board (Fed) FOMC Consensus Statement',
  'Federal Reserve Board (Fed) FOMC Balance Sheet Policy',
  'Federal Reserve Board (Fed) FOMC Memo',
  'Federal Reserve Board (Fed) Beige Book',
  'Federal Reserve Bank of Kansas City Speech',
  'Federal Reserve Bank of Atlanta Speech',
  'Federal Reserve Bank of San Francisco Speech',
  'Federal Reserve Bank of St. Louis Speech',
  'Federal Reserve Bank of Boston Speech',
  'Federal Reserve Bank of Cleveland Speech',
  'Federal Reserve Bank of Richmond Speech',
  'Federal Reserve Bank of New York Speech',
  'Federal Reserve Bank of Dallas Speech',
  'Federal Reserve Bank of Minneapolis Speech',
  'Federal Reserve Bank of Chicago Speech',
  'Federal Reserve Bank of Philadelphia Speech',
];

export const getAllAITools = () => {
  return Object.values(AI_TOOLS);
};

const EDGAR = [
  'Uploaded 10-K',
  'Uploaded 10-Q',
  'Uploaded 8-K',
  'Uploaded 20-F',
  'Uploaded 40-F',
  'Uploaded 6-K',
  'Uploaded S-1',
  'PREC14A',
  'DEFA14A',
  'DEFC14A',
  'DFAN14A',
  'DEF 14A',
  'PREM14A',
  'PRER14A',
  'PRE 14A',
  'DEFR14A',
  'DEFM14A',
  'DFRN14A',
  'PRRN14A',
];
const USER_DEFINABLE = [
  'Uploaded Earnings Call Transcript',
  'Uploaded Conference Transcript',
  'Loan Agreement',
  'Lender Call',
  'Prospectus',
  'Indenture',
  'Disclosure Statement',
  'Plan of Reorg',
  'Research',
  'Expert Network Call',
  'PPM',
  'CIM',
  'News Article',
  'Court Case Filing',
  'Contract',
  'Commentary',
  'Annual Report',
  'Financial Statements',
  'Financial Model',
  'Investor Letter',
  'Investment Memo',

  'Unaudited Statements/Transparency',
  'Agreements',
  'Audited Financial Statements',
  'Fund Services: Cash Flows/ Invoices/ Other Transactions',
  'Manager Periodic Investor Letter Update',
  'Presentation/Marketing Material/Other Communications',
  'Other Monitoring Documents',
  'Tax',
  'Other',
];
export const UPLOAD_DOC_TYPE_OPTIONS = [...EDGAR.sort(), ...USER_DEFINABLE.sort()];
export const UPLOADED_DOC_TYPES = [
  '10-K',
  '10-Q',
  '8-K',
  '20-F',
  '40-F',
  '6-K',
  'S-1',
  'Earnings Call Transcript',
  'Conference Transcript',
];
