import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { Body1, Body5, CheckCircle, Container, Warning } from '../../libs/nvstr-common-ui.es';
import { Spinner } from '../../components/UI/LoadingSpinner';
import { TickerSelect } from '../DigitalAnalyst/components/TickerSelect';
import { FormState } from '../../components/UI/FormState';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { DragDrop } from '../../components/UI/DragAndDropUploader';
import { useUploadFiles } from '../../hooks/features/useUploadFiles';
import { UploadFile } from '../application/UploadFile';
import { pluralize, stripFileExtension } from '../../utils/usefulFuncs';
import ErrorText from '../../components/UI/ErrorText';
import { ROUTES } from '../../constants/routes';
import { UPLOAD_DOC_TYPE_OPTIONS } from '../../constants';

const ARE_MULTIPLE_TICKERS_ENABLED = false;

const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 300px;
    width: 100%;
    padding: 16px;
  }
`;
const CheckWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;
const FileUploadProgressWrapper = styled.div`
  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.themeColors.proColor};
    }
  }

  .warning-icon {
    path {
      fill: ${({ theme }) => theme.themeColors.error};
    }
  }
`;
const Label = styled.div`
  padding-bottom: 8px;
`;
const RetryButtonWrapper = styled.div`
  button {
    padding: 4px 12px;
  }
`;
const SmallSpinner = styled.div`
  svg {
    height: 28px;
    width: 28px;
  }
`;

function FileUploadProgress({ data, retryUpload, ticker }) {
  const { status, name, errorMessage, type } = data;
  const handleRetry = () => {
    retryUpload(data, ticker);
  };
  return (
    <FileUploadProgressWrapper>
      <Container row>
        <Container>
          <Body5>{`${name}.${type.toLowerCase()}`}</Body5>
        </Container>
        {status === 200 && (
          <Container left={16}>
            <CheckCircle />
          </Container>
        )}
        {errorMessage && (
          <Container left={16}>
            <span className={'warning-icon'}>
              <Warning />
            </span>
          </Container>
        )}
      </Container>
      {errorMessage && (
        <Container row wrap top={8} left={16} verticallyCenter>
          <ErrorText>Error: {errorMessage}</ErrorText>
          <Container left={16}>
            <RetryButtonWrapper>
              <FlatButton onClick={handleRetry}>Retry</FlatButton>
            </RetryButtonWrapper>
          </Container>
        </Container>
      )}
    </FileUploadProgressWrapper>
  );
}

export const XLSUploadForm = () => {
  const navigate = useNavigate();

  const [files, setFiles] = React.useState([]);
  const [tickers, setTickers] = React.useState([]);
  const [docType, setDocType] = React.useState('');
  const [isAddingTicker, setIsAddingTicker] = React.useState(false);

  const {
    uploadFiles,
    retryUpload,
    uploadingFilesProgress,
    error: formError,
    isComplete: showUploadComplete,
    reset: resetUploadProgressState,
  } = useUploadFiles('xls');

  const handleDocTypeSelect = (e) => {
    setDocType(e.target.value);
  };
  const onToggleAddingTicker = () => setIsAddingTicker(!isAddingTicker);
  const onUploadAnotherClick = () => {
    setTickers([]);
    setFiles([]);
    resetUploadProgressState();
  };
  const onQueryItClick = () => {
    const normalizedName = stripFileExtension(files[0]);
    const encodedName = encodeURIComponent(normalizedName);
    navigate(`${ROUTES.docProcessingToDA}?filename=${encodedName}`);
  };
  const onAddTicker = (t) => setTickers([t]);
  const onRemoveTicker = (ticker) => {
    setTickers([]);
  };

  const handleAddFiles = (filesToAdd) => {
    const currentFileNames = files.map((f) => f.name);
    const filesFiltered = filesToAdd.filter((f) => !currentFileNames.includes(stripFileExtension(f)));
    setFiles([...files, ...filesFiltered]);
  };

  const onEditFile = (fileName, newFileName) => {
    const alreadyExists = files.some((f) => f.name === newFileName);
    if (newFileName && !alreadyExists) {
      setFiles(
        files.map((f) => {
          if (f.name === fileName) {
            f.name = newFileName;
            return f;
          } else {
            return f;
          }
        })
      );
      return null;
    }

    return 'Filename already exists. Reverted name.';
  };

  const onDeleteFile = (fileName) => {
    if (fileName) {
      setFiles(files.filter((f) => f.name !== fileName));
    }
  };

  const onSubmit = async () => {
    uploadFiles(files, tickers[0], { docType });
  };

  const onClear = () => {
    setFiles([]);
  };

  if (showUploadComplete) {
    return (
      <Container top={48}>
        <Container row verticallyCenter>
          <CheckWrapper>
            <CheckCircle />
          </CheckWrapper>
          <Container left={16}>
            <Container>
              <Body1 bold>Upload Complete</Body1>
            </Container>
            <Body1>The {files?.length === 1 ? 'document' : 'documents'} will be available to query shortly.</Body1>
          </Container>
        </Container>
        <Container top={48} row bottom={24}>
          {files?.length === 1 && <FlatButton onClick={onQueryItClick}>Use This Doc</FlatButton>}
          <Container left={16}>
            <SkeletonButton onClick={onUploadAnotherClick}>Upload More</SkeletonButton>
          </Container>
        </Container>
      </Container>
    );
  }

  if (uploadingFilesProgress.length > 0) {
    return (
      <Container top={48}>
        {uploadingFilesProgress.every((f) => !!f.status) ? (
          <Container>
            <Container>
              <Body1 bold>Processing complete</Body1>
            </Container>
            {uploadingFilesProgress.some((f) => f.status !== 200) ? (
              <Container top={8}>
                <Body5>
                  Unable to upload {uploadingFilesProgress.length > 1 ? 'all of the files' : 'your file'}, see below for
                  details.
                </Body5>
              </Container>
            ) : (
              <Container top={8}>
                <Body5>The documents should be available to query in a few minutes.</Body5>
              </Container>
            )}
          </Container>
        ) : (
          <Container row verticallyCenter>
            <Container right={20}>
              <SmallSpinner>
                <Spinner />
              </SmallSpinner>
            </Container>
            <Container>
              <Body1 bold>Processing {pluralize(uploadingFilesProgress.length, 'file')}...</Body1>
            </Container>
          </Container>
        )}

        <Container top={24} bottom={48}>
          {uploadingFilesProgress.map((f) => (
            <Container top={16} row key={f.name}>
              <FileUploadProgress data={f} retryUpload={retryUpload} ticker={tickers[0]} />
            </Container>
          ))}
        </Container>
      </Container>
    );
  }

  return (
    <Container>
      <FormWrapper>
        <Container top={24}>
          <h3>Upload Spreadsheet</h3>
        </Container>
        <Container top={48}>
          <DragDrop onAddFiles={handleAddFiles} files={files} xls />
        </Container>

        {files.length === 0 ? null : (
          <Container top={36}>
            {files.map((f) => (
              <Container key={f.name} bottom={8}>
                <UploadFile file={f} onEditFile={onEditFile} onDeleteFile={onDeleteFile} />
              </Container>
            ))}
          </Container>
        )}

        {isAddingTicker ? (
          <Container top={24}>
            <TickerSelect
              onAddTicker={onAddTicker}
              onRemoveTicker={onRemoveTicker}
              tickers={tickers}
              multipleTickersEnabled={ARE_MULTIPLE_TICKERS_ENABLED}
            />
          </Container>
        ) : (
          <Container top={24}>
            <SkeletonButton onClick={onToggleAddingTicker}>Assign A Stock Ticker To Files</SkeletonButton>
          </Container>
        )}
        <Container top={24}>
          <Label>
            <Body5>Document Type</Body5>
          </Label>
          <select value={docType} onChange={handleDocTypeSelect}>
            <option value={''}>Optional...</option>
            {UPLOAD_DOC_TYPE_OPTIONS.map((o) => (
              <option key={o}>{o}</option>
            ))}
          </select>
        </Container>

        <Container top={48}>
          <Container centerAll>
            <FormState error={formError} />
          </Container>
          <SubmitButtonWrapper>
            <FlatButton onClick={onSubmit} fullWidth disabled={files.length === 0}>
              {files.length > 1 ? 'Upload All' : 'Upload File'}
            </FlatButton>
          </SubmitButtonWrapper>
          <Container centerAll top={8}>
            <TransparentFlatButton onClick={onClear}>Clear</TransparentFlatButton>
          </Container>
        </Container>
      </FormWrapper>
    </Container>
  );
};
