import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { Body5 } from '../../libs/nvstr-common-ui.es';
import { calcSuggestedMinMax } from '../../utils/usefulFuncs';

const ChartHeader = styled.div`
  padding: 0 0 16px 48px;
`;
const ChartAnalysisGlobalWrapper = styled.div`
  /* Customizes the whole scrollbar (including track and thumb) */

  .chart-wrapper {
    scrollbar-color: #222 #fff;
  }

  .chart-wrapper::-webkit-scrollbar {
    width: 12px; /* Width of the vertical scrollbar */
    height: 12px; /* Height of the horizontal scrollbar */
  }

  /* Customizes the track (part the thumb slides within) */

  .chart-wrapper::-webkit-scrollbar-track {
    background: #fff; /* Color of the track */
  }

  /* Customizes the thumb (the draggable part of the scrollbar) */

  .chart-wrapper::-webkit-scrollbar-thumb {
    background: #ccc; /* Color of the thumb */
  }

  /* Changes the thumb color when hovered or clicked */

  .chart-wrapper::-webkit-scrollbar-thumb:hover {
    background: #222;
  }
`;
const ChartAnalysisWrapper = styled.div`
  overflow: auto;
`;
const ChartWrapper = styled.div`
  height: 460px;

  width: ${({ DataPoints }) => Math.max(1000, DataPoints * 15) + 'px'};
  padding: 0 0 10px 0;

  canvas {
    display: block;
    height: 460px;
    width: ${({ DataPoints }) => Math.max(1000, DataPoints * 15) + 'px'};
  }
`;

const generateOptions = (data) => {
  const { min, max } = calcSuggestedMinMax(data, 1);

  return {
    maintainAspectRatio: false,

    elements: {
      point: {
        radius: 3,
        hoverRadius: 10,
        pointStyle: 'circle',
      },
      line: {
        borderWidth: 2,
      },
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      spanGaps: true,
    },

    plugins: {
      tooltip: {
        // enabled: false,
      },
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: false,
        min,
        max,
        ticks: {
          min,
          max,
          stepSize: 0.5,
        },
        title: {
          display: true,
          text: 'Dovish - Neutral - Hawkish',
        },
      },
      x: {
        display: false,
        beginAtZero: false,
        grid: {
          tickWidth: 1,
          lineWidth: 1,
          color: (context) => (context.tick.major ? '#aaa' : '#fafafa'),
        },
        border: {
          display: false,
        },
        ticks: {
          color: '#666',
          font: {
            family: 'Bolton, Arial',
            size: 10,
          },
        },
      },
    },
  };
};

export const SpeakerDocSentimentChart = ({ data, activeKey }) => {
  const [options, setOptions] = React.useState(generateOptions(data));
  const chartData = {
    labels: data.labels,
    datasets: data.datasets,
  };

  React.useEffect(() => {
    setOptions(generateOptions(data));
  }, [data]);

  return (
    <ChartAnalysisGlobalWrapper>
      <ChartAnalysisWrapper className={'chart-wrapper'}>
        <ChartHeader>
          <Body5 bold>{`Speaker By Doc Sentiment Over Time (${activeKey})`}</Body5>
        </ChartHeader>
        <ChartWrapper>
          <Line data={chartData} options={options} />
        </ChartWrapper>
      </ChartAnalysisWrapper>
    </ChartAnalysisGlobalWrapper>
  );
};
