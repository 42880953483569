import React from 'react';
import { FOLLOW_UP_QUESTION_IDENTIFIER } from '../../constants';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { useAdvancedSettings } from '../application/useAdvancedSettings';
import { logFormSubmit, logNetRequest } from '../../utils/usefulFuncs';
import { addOptionalField } from '../../utils/application';

export const useAskDigitalAnalyst = (tool) => {
  const {
    model,
    seeContext,
    enableFollowUpQuestions,
    maxContextLength,
    includeInferredMetadata,
    maxAnswerLength,
    seed,
    maxContextChunks,
    temperature,
    topP,
  } = useAdvancedSettings();

  const [prevUsedModel, setPrevUsedModel] = React.useState(model);
  const [resultId, setResultId] = React.useState(null);
  const [citationsResultId, setCitationsResultId] = React.useState(null);
  const [questionAsked, setQuestionAsked] = React.useState(null);
  const [sourceDocs, setSourceDocs] = React.useState([]);

  const [formError, setFormError] = React.useState(null);
  const [isSubmittingToApi, setIsSubmittingToApi] = React.useState(false);

  const onSubmit = async ({
    question,
    forceQuestionAsked,
    selectedDocs,
    forceFollowUpQuestionOff,
    ticker,
    isResearchMode,
    model: forceModel,
    enableTextSearch,
    extra_context,
    isCitationsMode,
    record_id,
    isAPICitationsMode,
  }) => {
    if (selectedDocs?.length === 0 && !isAPICitationsMode) {
      setFormError('Please select at least one ticker or document to run a query');
      return;
    }
    if (question === '' && !isAPICitationsMode) {
      setFormError('Please enter a question to run a query on the selected tickers/documents.');
      return;
    }

    if (!isCitationsMode) {
      setSourceDocs(selectedDocs);
    }
    const enhancedQuestion = `${question}. At the end of your response include a section separated by the text "${FOLLOW_UP_QUESTION_IDENTIFIER}" with a numbered list of 3 additional suggested follow up questions you can make on the topic of this question and response that a stock analyst would be interested in.`;
    const _question = enableFollowUpQuestions && !forceFollowUpQuestionOff ? enhancedQuestion : question;
    const _model = forceModel || model;
    setPrevUsedModel(_model);
    if (!isCitationsMode) {
      setCitationsResultId(null);
    }
    const form = isAPICitationsMode
      ? {
          record_id: record_id,
          user_mode: tool,
        }
      : {
          question: _question,
          model: _model,
          user_mode: tool,
          doc_list: selectedDocs?.map((doc) => doc.id),
          research_mode: isResearchMode,
          need_context: seeContext,
          use_inferred_metadata: includeInferredMetadata || false,
          enable_text_search: !!enableTextSearch,
        };
    if (extra_context) {
      form.extra_context = extra_context;
    }
    if (ticker) {
      form.ticker = ticker;
    }
    addOptionalField('max_answer_tokens', maxAnswerLength, form);
    addOptionalField('max_context_tokens', maxContextLength, form);
    addOptionalField('max_context_chunks', maxContextChunks, form);
    addOptionalField('seed', seed, form);
    addOptionalField('temperature', temperature, form, true);
    addOptionalField('top_p', topP, form, true);

    logFormSubmit(form);

    TrackingEvent.create('Asked Digital Analyst Question', {
      Question: _question,
      Model: _model,
      Tickers: ticker,
      'Selected Docs': selectedDocs?.map((d) => d.filename),
      'Inserting Citations': isAPICitationsMode,
    });

    setFormError(false);
    if (!isCitationsMode) {
      setResultId(null);
    }
    setIsSubmittingToApi(true);
    setQuestionAsked(forceQuestionAsked || question);

    const URL = isAPICitationsMode ? `v1/genai_qa_citation/stream` : `v1/genai_qa/stream`;
    const { status, data, error } = await sendApiRequest('post', URL, form);
    setIsSubmittingToApi(false);
    logNetRequest(status, data, error);
    handle400Statuses(status);
    if (status === 200) {
      const { id } = data;
      isCitationsMode ? setCitationsResultId(id) : setResultId(id);
      setFormError(false);
    } else {
      if (!isCitationsMode) {
        setResultId(null);
      }
      setFormError(data?.error || error || 'Something went wrong, try again.');
      TrackingEvent.create('Asked Digital Analyst Question Failed', {
        Question: _question,
        'Inserting Citations': isAPICitationsMode,
        Error: data?.error || error || 'unknown',
      });
    }
  };

  const onClearResult = () => {
    setResultId(null);
    setCitationsResultId(null);
    setFormError(null);
    setSourceDocs([]);
  };

  return {
    onSubmit,
    onClearResult,
    sourceDocs,

    resultId,
    citationsResultId,

    prevUsedModel,
    questionAsked,
    formError,
    isSubmittingToApi,
  };
};
