import { useSelector } from 'react-redux';

export const AdvancedSettingsFields = {
  dailySummariesToggle: 'dst',
  enableJS: 'ejs',
  headlinesToggle: 'est',
  followUpQuestionsToggle: 'fuqt',
  includeUserDocsToggle: 'iudt',
  includeInferredMetadata: 'iimd',
  maxAnswerLength: 'mal',
  maxContextLength: 'mcl',
  model: 'model',
  overrideQuestion: 'oq',
  numOfPages: 'nop',
  seeContext: 'sc',
  smoothingToggle: 'st',
  enableTextSearch: 'enableTS',
  maxContextChunks: 'mcc',
  version: 'version',

  seed: 'seed',
  temperature: 'temperature',
  top_p: 'topP',
};

export function useAdvancedSettingsFields() {
  return useSelector((state) => state.application.advancedSettingsFields);
}

export function useShowAdvancedSettings() {
  const fields = useAdvancedSettingsFields();
  return fields?.length > 0;
}

export function useAdvancedSettings() {
  const state = useSelector((state) => state.application.advancedSettings);
  return state;
}
