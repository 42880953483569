import React from 'react';
import { Body5, Container, H5, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { CloseButton } from '../../components/buttons/CloseButton';
import { useColorTheme } from '../../hooks';
import { SentimentTranscript } from './SentimentTranscript';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import { Modal } from '../../components/layout/Modal';
import { SentimentAggregations } from './SentimentAggregations';
import { useHasBetaFeaturesEnabled } from '../../hooks/user/useCurrentUser';

const Wrapper = styled.div`
  overflow: hidden;
`;
const Heading = styled.div`
  h5 {
    padding-right: 8px;
  }
`;

const SelectedDocumentWrapper = styled.div``;
const IconWrapper = styled.div`
  margin-right: 8px;

  svg {
    padding-top: 1px;
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 8;
`;

function SelectedDocument({ doc }) {
  const [showIncludedDocuments, setShowIncludedDocuments] = React.useState(false);
  const [sortedDocumentList, setSortedDocumentList] = React.useState(null);

  React.useEffect(() => {
    if (doc) {
      const { document_metadata } = doc;
      const includedDocs = Object.values(document_metadata);
      const sorted = includedDocs.sort((a, b) => {
        const afilename = a.filename.split('"').join('');
        const bfilename = b.filename.split('"').join('');
        if (afilename < bfilename) {
          return -1;
        }
        if (afilename > bfilename) {
          return 1;
        }
        return 0;
      });
      setSortedDocumentList(sorted);
    }
  }, [doc]);

  if (!doc) return null;

  const { filename, doc_ids } = doc;
  const displayName = doc_ids.length > 1 ? `${doc_ids.length} Selected Speeches/Documents` : filename;

  const onViewIncludedDocs = () => {
    setShowIncludedDocuments(true);
  };
  const onClose = () => {
    setShowIncludedDocuments(false);
  };

  return (
    <SelectedDocumentWrapper>
      {showIncludedDocuments && (
        <Modal onDismiss={onClose} modalProps={{ noPadding: true }}>
          <CloseButtonWrapper>
            <CloseButton onClick={onClose} />
          </CloseButtonWrapper>
          <Container top={32}>
            {sortedDocumentList?.map((d) => (
              <Container key={d.id} bottom={12}>
                <Body5>{d.filename}</Body5>
              </Container>
            ))}
          </Container>
        </Modal>
      )}

      <Container row verticallyCenter>
        <IconWrapper>
          <DocumentIcon />
        </IconWrapper>
        <Container>
          <Body5>{displayName}</Body5>
        </Container>

        <Container>
          <TransparentFlatButton onClick={onViewIncludedDocs}>View Included Speeches/Documents</TransparentFlatButton>
        </Container>
      </Container>
    </SelectedDocumentWrapper>
  );
}

const DownloadCSV = ({ data }) => {
  const downloadCSV = () => {
    const filename = 'sentiment.csv';
    const blob = new Blob([data], { type: 'text/csv' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Container>
      <FlatButton onClick={downloadCSV}>Download CSV</FlatButton>
    </Container>
  );
};

export const SentimentAnalysis = ({
  isLoading,
  data,
  rawCSV,
  documentTranscriptLookup,
  metadata,
  doc,
  handleReset,
}) => {
  const betaPermissions = useHasBetaFeaturesEnabled();
  const colorTheme = useColorTheme();

  const [highlightStatementIndex, setHighlightStatementIndex] = React.useState(null);

  const onClearHighlight = () => {
    setHighlightStatementIndex(null);
  };

  React.useEffect(() => {
    const scrollToAnalysis = () => {
      const id = 'question-answer';
      const yOffset = -30;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      setTimeout(() => {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 600);
    };
    scrollToAnalysis();
  }, []);

  if (isLoading) {
    return (
      <Page width={'1400px'}>
        <Wrapper>
          <div id={'question-answer'}></div>

          <Container>
            <Heading>
              <Container row verticallyCenter spaceBetween>
                <H5>Fed Sentiment</H5>
              </Container>
            </Heading>
            <Container top={12}>
              <SelectedDocument doc={doc} />
            </Container>
            <Container top={80} bottom={80} centerAll>
              <Container verticallyCenter top={64} bottom={64}>
                <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
                <Container left={12}>
                  <Body5>Working...</Body5>
                </Container>
              </Container>
            </Container>
          </Container>
        </Wrapper>
      </Page>
    );
  }

  return (
    <>
      <Page width={'1400px'}>
        <Wrapper>
          <div id={'question-answer'}></div>
          <Heading>
            <Container row verticallyCenter spaceBetween>
              <H5>Fed Sentiment</H5>

              <CloseButton onClick={handleReset} />
            </Container>
          </Heading>
          <Container top={12}>
            <SelectedDocument doc={doc} />
          </Container>
          {betaPermissions && (
            <Container top={12}>
              <DownloadCSV data={rawCSV} />
            </Container>
          )}
          <Container top={24}>
            <SentimentAggregations metadata={metadata} />
          </Container>
        </Wrapper>
      </Page>
      <Page width={'1400px'}>
        <Wrapper>
          <Container>
            <H5>Document Analysis</H5>
          </Container>
          <Container top={24}>
            <SentimentTranscript
              data={data}
              documentTranscriptLookup={documentTranscriptLookup}
              metadata={metadata}
              highlightStatementIndex={highlightStatementIndex}
              onClearHighlight={onClearHighlight}
            />
          </Container>
        </Wrapper>
      </Page>
    </>
  );
};
