import React from 'react';
import styled from 'styled-components';
import { Body1, Body5, Container } from '../../libs/nvstr-common-ui.es';
import { logger } from '../../utils/usefulFuncs';
import { TransparentFlatButton } from '../../components/buttons';
import { CloseButton } from '../../components/buttons/CloseButton';
import { DOVISH_COLOR, HAWKISH_COLOR } from '../../constants';
import { SentimentSentence } from './components/SentimentSentence';
import { SentimentChart } from './SentimentChart';
import { createTimeInstance } from '../../libs/nvstr-utils.es';

const Wrapper = styled.div`
  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }
`;
const ColorWrapper = styled.div`
  background: ${({ Value }) => Value};
  height: 15px;
  width: 40px;
`;
const Color = ({ value }) => {
  return <ColorWrapper Value={value} />;
};
const Legend = () => {
  return (
    <Container row verticallyCenter>
      <Container row verticallyCenter>
        <Color value={HAWKISH_COLOR} />
        <Container left={16}>
          <Body5>Hawkish</Body5>
        </Container>
      </Container>
      <Container left={16} row verticallyCenter>
        <Color value={DOVISH_COLOR} />
        <Container left={16}>
          <Body5>Dovish</Body5>
        </Container>
      </Container>
    </Container>
  );
};

const SpeakerWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 12px;
`;
const StatementsWrapper = styled.div`
  padding-left: 8px;
`;
const TextAnalysisWrapper = styled.div`
  padding-top: 8px;
`;
const HighlightStatsWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  left: 50px;

  padding: 16px 32px;
  border-radius: 5px;
  background: ${({ theme }) => theme.themeColors.primaryCtaButton};

  color: ${({ theme }) => theme.themeColors.buttonText} !important;
  z-index: 4;

  * {
    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 1000;

  * {
    z-index: 6;
  }

  svg {
    height: 12px;
    width: 12px;
  }

  path {
    // fill: ${({ theme }) => '#fff'};
    fill: #fff !important;
  }
`;

const HighlightStats = ({ statements, highlightStatementIndex, onClearHighlight }) => {
  const [show, setShow] = React.useState(false);
  const [statement, setStatement] = React.useState(null);

  React.useEffect(() => {
    if (highlightStatementIndex !== null) {
      setShow(true);
      const s = statements[highlightStatementIndex];
      if (s) {
        setStatement(s);
      } else {
        logger('missing statement');
      }
    } else {
      if (show) {
        setShow(false);
        setStatement(null);
      }
    }
  }, [show, highlightStatementIndex, statements]);

  const handleScrollTo = () => {
    const yOffset = -16;
    const id = 'statement-' + highlightStatementIndex;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    setTimeout(() => {
      window.scrollTo({ top: y, behavior: 'smooth' });
    }, 100);
  };

  const onCloseClick = () => {
    // setShow(false);
    // setStatement(null);
    onClearHighlight();
  };

  if (!show || !statement) {
    return null;
  }
  return (
    <HighlightStatsWrapper>
      <CloseButtonWrapper>
        <CloseButton onClick={onCloseClick} />
      </CloseButtonWrapper>
      <Body1 bold>Sentence:</Body1>
      <Container top={8}>
        <Body1 bold>{statement.statement}</Body1>
      </Container>
      <Container top={8}>
        <Body1 bold>Score: </Body1>
        <Body1 bold>{statement.score}</Body1>
      </Container>
      <Container centerAll>
        <TransparentFlatButton onClick={handleScrollTo}>Scroll To Statement</TransparentFlatButton>
      </Container>
    </HighlightStatsWrapper>
  );
};
const SpeakerParagraph = ({ lines, highlightStatementIndex, onClearHighlight }) => {
  const { speaker } = lines[0];
  return (
    <>
      <SpeakerWrapper>
        <Body5>{speaker}</Body5>
      </SpeakerWrapper>
      <HighlightStats
        statements={lines}
        highlightStatementIndex={highlightStatementIndex}
        onClearHighlight={onClearHighlight}
      />
      <StatementsWrapper>
        {lines.map((d, i) => (
          <SentimentSentence
            key={i}
            index={i}
            data={d}
            enableHoverStats={highlightStatementIndex === null}
            isHighlighted={i === highlightStatementIndex}
          />
        ))}
      </StatementsWrapper>
    </>
  );
};

const Heading = ({ data: headings }) => {
  if (!headings) return null;
  return (
    <Container top={24} bottom={16}>
      <Body1 bold>{headings[0].statement}</Body1>
    </Container>
  );
};

export const SentimentTranscriptReader = ({ data, metadata, highlightStatementIndex, onClearHighlight }) => {
  if (!data) {
    return (
      <Container>
        <Body5>An error occurred</Body5>
      </Container>
    );
  }
  return (
    <>
      <Heading data={metadata.headings} />
      <Legend />
      <TextAnalysisWrapper>
        {data?.groupedLinesBySpeaker?.map((d, i) => (
          <SpeakerParagraph
            key={i}
            lines={d}
            highlightStatementIndex={highlightStatementIndex}
            onClearHighlight={onClearHighlight}
          />
        ))}
      </TextAnalysisWrapper>
    </>
  );
};

function convertLinesToChartData(lines) {
  const labels = [];
  const datapoints = [];
  lines.forEach((d, i) => {
    const { statement, score } = d;
    labels.push(statement);
    const datapoint = {
      x: i,
      y: parseFloat(score),
    };
    datapoints.push(datapoint);
  });
  return {
    labels,
    datapoints,
  };
}

function getMostRecentTranscript(docs, metadata) {
  let mostRecent = null;
  docs.forEach((doc) => {
    if (mostRecent === null) {
      mostRecent = doc;
    } else {
      const { published_at } = doc;
      if (createTimeInstance(published_at).isAfter(createTimeInstance(mostRecent.published_at))) {
        mostRecent = doc;
      }
    }
  });
  return mostRecent;
}

export const SentimentTranscript = ({
  documentTranscriptLookup,
  metadata,
  highlightStatementIndex,
  onClearHighlight,
}) => {
  const [documents, setDocuments] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [chartData, setChartData] = React.useState(null);
  const [sortedAvailableDocuments, setSortedAvailableDocuments] = React.useState(null);

  React.useEffect(() => {
    if (metadata.documents) {
      const documents = Object.values(metadata.documents);
      setDocuments(documents);
    }
  }, [metadata]);

  React.useEffect(() => {
    if (documents) {
      const sorted = documents.sort((a, b) => {
        const afilename = a.filename.split('"').join('');
        const bfilename = b.filename.split('"').join('');
        if (afilename < bfilename) {
          return -1;
        }
        if (afilename > bfilename) {
          return 1;
        }
        return 0;
      });
      const availableDocumentIds = Object.keys(documentTranscriptLookup).map((id) => parseInt(id, 10));
      const availableSorted = sorted.filter((doc) => availableDocumentIds.includes(doc.id));
      setSortedAvailableDocuments(availableSorted);
      setSelected(getMostRecentTranscript(availableSorted, metadata).id);
    }
  }, [documents]);

  React.useEffect(() => {
    if (selected && documentTranscriptLookup[selected]) {
      const transcript = documentTranscriptLookup[selected];
      const chartData = {
        ...convertLinesToChartData(transcript.lines),
      };
      setChartData(chartData);
    }
  }, [selected]);

  const handleSelectedDocumentChange = (e) => {
    const value = e.target.value;
    setSelected(value);
  };

  if (documents === null || sortedAvailableDocuments === null) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <Container bottom={8}>
          <Body5>Selected Transcript Reader Document/Speech</Body5>
        </Container>
        <select value={selected} onChange={handleSelectedDocumentChange}>
          {sortedAvailableDocuments.map((d) => (
            <option key={d.id} value={d.id}>
              {d.filename}
            </option>
          ))}
        </select>
      </Container>

      {chartData !== null && (
        <Container top={32}>
          <SentimentChart data={chartData} />
        </Container>
      )}
      <Container top={24}>
        <SentimentTranscriptReader
          docId={selected}
          data={documentTranscriptLookup[selected]}
          metadata={metadata}
          highlightStatementIndex={highlightStatementIndex}
          onClearHighlight={onClearHighlight}
        />
      </Container>
    </Wrapper>
  );
};
