import Papa from 'papaparse';
import { convertHexToRGBA, createTimeInstance, moment } from '../../libs/nvstr-utils.es';
import { logError } from '../usefulFuncs';

const usedColors = [
  '#222',
  '#3477eb',
  '#34eb5e',
  '#eba134',
  '#ebe134',
  '#eb4334',
  '#6e34eb',
  '#c334eb',
  '#f291e2',
  '#8e89f5',
  '#7db1e8',
  '#c2ebbe',
  '#125c0b',
  '#e6d0aa',
  '#750a05',
  '#999',
  '#ddd',
  '#f5f097',
  '#053b75',
  '#4a3209',
  '#380704',
  '#494a09',
];

function updateHeadings(r, metadata) {
  const { speaker } = r;
  if (speaker === 'Heading') {
    if (metadata.headings) {
      metadata.headings.push(r);
    } else {
      metadata.headings = [r];
    }
  }
}

function createGroup(r, a) {
  a.push({
    speaker: r.speaker,
    statements: [r],
  });
}

function addStatement(r, item) {
  item.statements.push(r);
}

function buildLabelIndexes(arr) {
  const result = [0];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] === arr[i - 1]) {
      // result.push('');
    } else {
      result.push(i);
    }
  }
  return result;
}

function calculateWeightedAverage(data, weights = [1, 0.5, 0.25, 0.125]) {
  // Array to hold the weighted averages
  let weightedAverages = [];

  // Iterate over the data array
  for (let i = 0; i < data.length; i++) {
    let weightedSum = 0;
    let sumOfWeightsUsed = 0;

    // Calculate weighted sum for the current point and up to two previous points
    for (let j = 0; j < weights.length; j++) {
      if (i - j >= 0) {
        // Check if the index exists in the array
        weightedSum += data[i - j] * weights[j];
        sumOfWeightsUsed += weights[j];
      }
    }

    // Divide by the sum of weights used to normalize in case of the first two elements
    weightedAverages.push(weightedSum / sumOfWeightsUsed);
  }

  return weightedAverages;
}

function updateSpeakerMetadata(speaker, metadata) {
  if (!metadata.speakers) {
    metadata.speakers = [speaker];
    return;
  }

  if (!metadata.speakers.includes(speaker)) {
    metadata.speakers.push(speaker);
  }
}

export const parseCSV = (csvString) => {
  return new Promise((resolve, reject) => {
    Papa.parse(csvString, {
      header: true, // Indicates that the first row of the CSV are headers
      delimiter: ',', // Specifies the delimiter character
      skipEmptyLines: true, // Skips empty lines in the input
      complete: function (results) {
        resolve(results.data);
      },
    });
  });
};

export const sortTranscriptStatementOrder = (data) => {
  return data.sort((a, b) => {
    const { seq_num_start: sequencePosA } = a;
    const { seq_num_start: sequencePosB } = b;
    return sequencePosA - sequencePosB;
  });
};

export const applyFilters = (data) => {
  return data.filter((d) => {
    return d.speaker !== 'Heading';
  });
};

export const format = (result) => {
  const formatted = [];
  result.forEach((r) => {
    const { speaker } = r;
    if (formatted.length === 0) {
      createGroup(r, formatted);
    } else {
      const lastSpeaker = formatted[formatted.length - 1].speaker;
      if (lastSpeaker === speaker) {
        addStatement(r, formatted[formatted.length - 1]);
      } else {
        createGroup(r, formatted);
      }
    }
  });
  return formatted;
};

export function buildMetadata(result, data) {
  const { aggregation_by_speaker, aggregation_by_doc, aggregation_by_doc_and_speaker, document_metadata } = data;
  const metadata = {
    aggregation_by_speaker,
    aggregation_by_doc,
    aggregation_by_doc_and_speaker,
    documents: document_metadata,
  };
  result.forEach((r, i) => {
    const { speaker } = r;
    updateHeadings(r, metadata);
    updateSpeakerMetadata(speaker, metadata);
  });
  return metadata;
}

export function buildChartData(result) {
  const datapoints = [];
  const labels = [];
  result.forEach((r, i) => {
    const score = parseFloat(r.score);
    labels.push(r.speaker);

    datapoints.push(score);
  });
  const labelIndexes = buildLabelIndexes(labels);
  return { labels, datapoints: calculateWeightedAverage(datapoints), labelIndexes };
}

const formatMetricValue = (v) => v.toFixed(3);

const formatData = (data) => {
  const list = Object.entries(data);
  return list.map((a) => {
    try {
      const v = {
        key: a[0],
        value: formatMetricValue(a[1]),
      };
      return v;
    } catch (e) {
      logError(a);
    }
  });
};
export const formatSpeakerAggregationData = (metadata) => {
  const { aggregation_by_speaker: data, documents } = metadata;
  const { docsLookup, docIds } = formatDocsDataInAggregation(data);
  const formattedLookupByDocId = {};

  const ignoreKeys = ['min', 'max'];
  const availableMetricKeys = parseAvailableMetricKeys(docsLookup[docIds[0]]).filter((k) => !ignoreKeys.includes(k));

  const list = Object.entries(data);
  const filtered = list.filter((e) => {
    return e[0] !== 'Heading';
  });
  return filtered.map((s) => ({
    speaker: s[0],
    scores: formatData(s[1]),
  }));
};

const formatDocsDataInAggregation = (data) => {
  const recordId = Object.keys(data)[0];
  const docsLookup = data[recordId];
  const docIds = Object.keys(data[recordId]);
  return {
    docsLookup,
    docIds,
  };
};

const sortDocIdsByTime = (ids, docLookup) => {
  const docs = ids.map((id) => docLookup[id]);
  return docs
    .sort((a, b) => {
      const { report_date: aReportDate, published_at: aPublishedAt } = a;
      const { report_date: bReportDate, published_at: bPublishedAt } = b;
      const aDate = createTimeInstance(aReportDate || aPublishedAt);
      const bDate = createTimeInstance(bReportDate || bPublishedAt);
      return moment(aDate).valueOf() - moment(bDate).valueOf();
    })
    .map((d) => ({
      id: d.id,
      ...d,
      report_date: d.report_date || d.published_at,
    }));
};

const parseAvailableMetricKeys = (doc) => {
  const keys = Object.keys(doc);
  return keys;
};

const parseAvailableMetricKeysSpeakerDoc = (doc) => {
  const keys = Object.keys(doc)[0];
  return Object.keys(doc[keys]);
};

function generateChartDataByMetricKey(metricKey, sorted, formattedLookupByDocId, documents) {
  const labels = [];
  const datapoints = [];
  sorted.forEach((doc, i) => {
    const { id: docId, filename, report_date } = doc;
    const docValues = formattedLookupByDocId[docId];
    if (!docValues) {
      console.log('missing docValues', { doc, docId, formattedLookupByDocId });
    } else {
      const valueObj = docValues.filter((dv) => dv?.key === metricKey)[0];
      if (!valueObj) {
        console.log('missing values', { docId, formattedLookupByDocId });
      } else {
        const y = valueObj.value;
        const x = i;
        if (x === null) {
          console.log('null found', {
            doc,
            docValues,
          });
        }
        labels.push(filename);
        datapoints.push({
          x,
          y,
        });
      }
    }
  });
  return {
    labels,
    datapoints,
  };
}

export const formatDocAggregationData = (metadata) => {
  const { aggregation_by_doc: data, documents } = metadata;
  const { docsLookup, docIds } = formatDocsDataInAggregation(data);
  const formattedLookupByDocId = {};

  const ignoreKeys = ['min', 'max'];
  const availableMetricKeys = parseAvailableMetricKeys(docsLookup[docIds[0]]).filter((k) => !ignoreKeys.includes(k));
  docIds.forEach((dId) => {
    const doc = docsLookup[dId];
    formattedLookupByDocId[dId] = formatData(doc);
  });

  const sorted = sortDocIdsByTime(docIds, documents);
  const chartDataByKey = {};
  availableMetricKeys.forEach((metricKey) => {
    chartDataByKey[metricKey] = generateChartDataByMetricKey(metricKey, sorted, formattedLookupByDocId, documents);
  });
  return chartDataByKey;
};

const formatDocSpeakerAggregationData = (data) => {
  const list = Object.entries(data);
  const filtered = list.filter((e) => {
    return e[0] !== 'Heading';
  });
  return filtered.map((s) => ({
    speaker: s[0],
    scores: formatData(s[1]),
  }));
};

function updateSpeakers(speakers, docData) {
  const docSpeakers = Object.keys(docData);
  docSpeakers.forEach((s) => {
    if (!speakers.includes(s)) {
      speakers.push(s);
    }
  });
}

function generateDatasetsLookup(speakers) {
  const datasets = {};

  speakers.forEach((speaker, i) => {
    const color = usedColors[i] || '#222';
    const d = {
      label: speaker,
      data: [],
      fill: false,
      backgroundColor: color,
      borderColor: convertHexToRGBA(color, 1),
      tension: 0.1,
      spanGaps: true,
    };
    datasets[speaker] = d;
  });
  return datasets;
}

function generateSpeakerDocChartDataByMetricKey(metricKey, sorted, speakers, formattedLookupByDocId, documents) {
  const labels = [];
  const datasetsLookup = generateDatasetsLookup(speakers);

  sorted.forEach((doc) => {
    const { id: docId, filename } = doc;
    // const x = doc.report_date || doc.published_at;
    const x = filename;
    labels.push(filename);

    const docSpeakersScores = formattedLookupByDocId[docId];
    const speakersInDoc = docSpeakersScores.map((i) => i.speaker);
    const speakersNotInDoc = speakers.filter((s) => !speakersInDoc.includes(s));
    docSpeakersScores.forEach((speakerScoreData) => {
      const { speaker, scores } = speakerScoreData;
      const valueObj = scores.filter((dv) => (dv ? dv.key === metricKey : false))[0];
      const y = valueObj ? valueObj.value : 0;
      datasetsLookup[speaker].data.push({
        x,
        y,
      });
    });
    speakersNotInDoc.forEach((speaker) => {
      datasetsLookup[speaker].data.push({
        x,
        y: null,
      });
    });
  });
  return {
    labels,
    datasets: Object.values(datasetsLookup),
  };
}

export const formatSpeakerByDocAggregationData = (metadata) => {
  const { aggregation_by_doc_and_speaker: data, documents } = metadata;
  const { docsLookup, docIds } = formatDocsDataInAggregation(data);
  const ignoreKeys = ['min', 'max'];
  const availableMetricKeys = parseAvailableMetricKeysSpeakerDoc(docsLookup[docIds[0]]).filter(
    (k) => !ignoreKeys.includes(k)
  );

  const formattedLookupByDocId = {};
  const speakers = [];

  docIds.forEach((dId) => {
    const docData = docsLookup[dId];
    updateSpeakers(speakers, docData);
    formattedLookupByDocId[dId] = formatDocSpeakerAggregationData(docData);
  });

  const sorted = sortDocIdsByTime(docIds, documents);
  const chartDataByKey = {};
  availableMetricKeys.forEach((metricKey) => {
    chartDataByKey[metricKey] = generateSpeakerDocChartDataByMetricKey(
      metricKey,
      sorted,
      speakers,
      formattedLookupByDocId,
      documents
    );
  });
  return chartDataByKey;
};
