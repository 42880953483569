import React from 'react';
import styled from 'styled-components';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { Body1, Body5, Container, H5, Page } from '../../libs/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { useSentimentAnalysis } from '../../hooks/features/useSentimentAnalysis';
import { SentimentAnalysis } from './SentimentAnalysis';
import { useSentimentRecords } from '../../hooks/features/useSentimentRecords';
import { Working } from '../../components/UI/Working';
import { isUndefinedOrNull } from '../../utils/usefulFuncs';

// const defaultAdvancedSettingsFields = [];
// const defaultAdvancedSettings = {};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }
`;
const Heading = styled.div`
  padding: 36px 0 0 0;

  h5 {
    padding-right: 8px;
  }
`;
const ActionWrapper = styled.div`
  text-align: center;
  padding: 36px 0 0 0;
`;

const buildDisplayName = (doc) => {
  const docCount = doc.doc_ids.length;
  return docCount > 1 ? `${docCount} Documents/Speeches` : `${doc.filename}`;
};

function SentimentDocSelectTool({ onGoBack }) {
  const [selectedDoc, setSelectedDoc] = React.useState('');
  const [docInAnalysis, setDocInAnalysis] = React.useState(null);
  const [formError, setFormError] = React.useState(null);

  const { data: selectableDocuments, error: recordsError } = useSentimentRecords();
  const {
    isLoading,
    data,
    rawCSV,
    documentTranscriptLookup,
    chartData,
    metadata,
    error: analysisError,
    onSubmit,
    onClear,
  } = useSentimentAnalysis();

  const onReset = () => {
    setSelectedDoc(null);
    setDocInAnalysis(null);
    onClear();
  };

  const onChangeDocument = (e) => {
    const value = e.target.value;
    if (isUndefinedOrNull(value)) {
      return null;
    }
    setDocInAnalysis(null);
    onClear();
    console.log('selection', value);
    setSelectedDoc(value || '');
  };

  const onGetAnalysis = () => {
    if (selectedDoc === '') {
      setFormError('Please select a document in the document selection dropdown');
      return;
    }

    const selected = selectableDocuments.filter((d) => d.record_id == selectedDoc)[0];
    if (!selected) {
      console.log('nothing selected', { selected, selectedDoc });
      return;
    }

    setFormError(null);
    setDocInAnalysis(selected);
    onSubmit(selected);
  };

  const error = recordsError || analysisError;
  return (
    <Wrapper>
      <Page width={'1400px'}>
        <BackArrowButton onClick={selectedDoc === null ? onGoBack : onReset} />
        <Heading>
          <H5>Fed Sentiment</H5>
          <Container top={8}>
            <Body5>
              Analyze speaker sentiment in any of the available Fed documents. Scores from dovish, neutral, hawkish are
              shown from sentence to sentence, along with a averaged time-series across each document/speech.
            </Body5>
          </Container>
          {/*<AdvancedSettingsButton fields={defaultAdvancedSettingsFields} />*/}
        </Heading>
        <Container top={24} bottom={24}>
          <select value={selectedDoc?.record_id} onChange={onChangeDocument}>
            <option value={''}>Please select...</option>
            {selectableDocuments?.map((doc) => (
              <option key={doc.record_id} value={doc.record_id}>
                {buildDisplayName(doc)}
              </option>
            ))}
          </select>
          <ActionWrapper>
            {formError && (
              <Container bottom={16}>
                <Body5 error>{formError}</Body5>
              </Container>
            )}
            <FlatButton fullWidth onClick={onGetAnalysis}>
              Analyze Sentiment
            </FlatButton>
          </ActionWrapper>
        </Container>
      </Page>
      {error && (
        <Page width={'1400px'}>
          <Container vertical={16}>
            {analysisError && (
              <Container>
                <Body1 bold>An error occurred analyzing this document.</Body1>
              </Container>
            )}
            <Container top={16} bottom={48}>
              <Body5>{error}</Body5>
            </Container>
          </Container>
        </Page>
      )}
      {isLoading && (
        <Page width={'1400px'}>
          <Container vertical={16}>
            <Working />
          </Container>
        </Page>
      )}
      {data && (
        <SentimentAnalysis
          data={data}
          rawCSV={rawCSV}
          documentTranscriptLookup={documentTranscriptLookup}
          chartData={chartData}
          metadata={metadata}
          doc={docInAnalysis}
          isLoading={isLoading}
          handleReset={onReset}
        />
      )}
    </Wrapper>
  );
}

export default SentimentDocSelectTool;
