import React from 'react';
import styled from 'styled-components';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { Answer } from './AnswerLine';
import { ROUTES } from '../../../constants/routes';
import { Star } from '../../../assets/icons/svgs/Star';
import { CloseButton } from '../../../components/buttons/CloseButton';
import { Collapse } from '../../../assets/icons/svgs/Collapse';
import { Expand } from '../../../assets/icons/svgs/Expand';
import { IconButton } from '../../../components/buttons/IconButton';
import { useNewCompanySnapshotStreamingResult } from '../../../hooks/features/useNewCompanySnapshotStreamingResult';
import { handle400Statuses, sendApiRequest } from '../../../services/api';
import { logNetRequest } from '../../../utils/usefulFuncs';
import { gridTableAnalysisActions } from '../../../constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Edit } from '../../../assets/icons/svgs/Edit';
import { AddPromptModal } from '../../TableAnalysis/tableComponents/AddPromptRow';
import { TransparentFlatButton } from '../../../components/buttons';
import { useAdvancedSettings } from '../../../hooks/application/useAdvancedSettings';
import { Working } from '../../../components/UI/Working';
import useWindowSize from '../../../hooks/application/useWindowSize';
import { addOptionalField } from '../../../utils/application';

function _createNullArray(length) {
  return Array.from({ length }, () => null);
}

const Wrapper = styled.div`
  //
`;
const AnswerWrapper = styled.div`
  margin-top: -16px;
`;
const TableWrapper = styled.table`
  width: 100%;
  margin-top: 24px;

  th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  }

  .question-column {
    //min-width: 299px;
  }

  th,
  td {
    vertical-align: top;
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  }
`;
const TableRowWrapper = styled.tr``;
const DocLink = styled.tr`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
const CellActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  padding-bottom: 12px;
`;
const CellWrapper = styled.td`
  width: ${({ cellWidth }) => cellWidth + 'px'};
`;
const TableHeadingCellWrapper = styled.th`
  width: ${({ cellWidth }) => cellWidth + 'px'};
`;

function CellAnswer({ index, answersLookup, cellWidth }) {
  if (!answersLookup || !answersLookup[index]) {
    return (
      <CellWrapper className={'response'} cellWidth={cellWidth}>
        <Working noVerticalPadding />
      </CellWrapper>
    );
  }

  const answer = answersLookup[index];
  return (
    <CellWrapper className={'response'} cellWidth={cellWidth}>
      <Body5>
        <AnswerWrapper>
          <Answer result={answer} />
        </AnswerWrapper>
      </Body5>
    </CellWrapper>
  );
}

const calcTableWidth = (windowWidth) => windowWidth * 0.95 - 48 - 16;
const calcCellWidth = (windowWidth, cellCount) => Math.floor(calcTableWidth(windowWidth) / cellCount);

const useFavoritePrompt = (prompt) => {
  const favoriteQuestions = useSelector((state) => state.gridTableAnalysis.favoriteQuestions);
  const [fav, setFav] = React.useState(null);

  React.useEffect(() => {
    let found = null;
    favoriteQuestions.forEach((f) => {
      if (f.prompt === prompt.prompt) {
        found = f;
      }
    });
    if (found) {
      if (fav && found.id !== fav.id) {
        setFav(found);
      } else if (fav === null) {
        setFav(found);
      }
    }
    if (!found && fav?.id) {
      setFav(null);
    }
  }, [prompt, favoriteQuestions]);

  return fav;
};

function TableRow({ index, data, cellWidth, tool }) {
  const dispatch = useDispatch();
  const { model, maxContextLength, maxAnswerLength, maxContextChunks, seed, temperature, topP } = useAdvancedSettings();

  const { prompt, documents, cellCount } = data;

  const favoriteQuestionData = useFavoritePrompt(prompt);
  const [emptyCells, setEmptyCells] = React.useState(_createNullArray(cellCount));
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [resultId, setResultId] = React.useState(null);
  const [cells, setCells] = React.useState(null);
  const { answersLookup, error: streamError } = useNewCompanySnapshotStreamingResult(resultId);

  const promptCheck =
    favoriteQuestionData === null ? true : !favoriteQuestionData.is_default && favoriteQuestionData.is_my_prompt;
  const showFavButton = !prompt.block_editting && promptCheck;
  const isFavorite = favoriteQuestionData?.id > 0;
  const displayPrompt = prompt.block_editting ? prompt.display_label : prompt.display_label || prompt.prompt;

  const init = async () => {
    const docIdLists = documents.filter((d) => d !== null).map((r) => r.map((d) => d.id));
    const URL = `v1/genai_query_grid`;
    let form = {
      prompts: [prompt],
      doc_id_lists: docIdLists,
      user_mode: tool,
      model,
      max_answer_tokens: maxAnswerLength || null,
      max_context_tokens: isNaN(maxContextLength) ? 32000 : parseInt(maxContextLength, 10),
    };
    addOptionalField('max_context_chunks', maxContextChunks, form);
    addOptionalField('seed', seed, form);
    addOptionalField('temperature', temperature, form, true);
    addOptionalField('top_p', topP, form, true);
    const { status, data, error } = await sendApiRequest('post', URL, form);
    handle400Statuses(status);
    logNetRequest(URL, status, data);

    if (status === 200) {
      const row = data.table[0];
      setCells(row.cells);
      setResultId(data.result_id);
    } else {
      setError('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      init();
    }, index * 100 || 1);

    return () => {
      setCells(null);
      setResultId(null);
    };
  }, [prompt]);

  const onRetry = () => {
    setError(null);
    setCells(null);
    setResultId(null);
    init();
  };

  React.useEffect(() => {
    if (cellCount !== emptyCells.length) {
      setEmptyCells(_createNullArray(cellCount));
    }
  }, [cellCount]);

  const handleCollapse = () => {
    setIsCollapsed(true);
  };
  const handleExpand = () => {
    setIsCollapsed(false);
  };
  const handleDeleteRow = () => {
    dispatch({
      type: gridTableAnalysisActions.deleteTableRow,
      payload: {
        index,
      },
    });
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleFav = async () => {
    const form = {
      ...prompt,
      display_label: prompt.display_label || prompt.prompt,
      user_mode: tool,
      user_modes: [tool],
    };
    const URL = `v1/genai_favorite_prompts`;
    const { status, data, error } = await sendApiRequest('post', URL, form);
    if (status === 200) {
      dispatch({
        type: gridTableAnalysisActions.addFavoriteQuestion,
        payload: data,
      });
    }
  };
  const handleUnFav = async () => {
    const id = favoriteQuestionData.id;
    const URL = `v1/genai_favorite_prompts/${id}`;
    const { status, data, error } = await sendApiRequest('delete', URL);
    logNetRequest(status, data);
    if (status === 200) {
      dispatch({
        type: gridTableAnalysisActions.removeFavoriteQuestion,
        payload: id,
      });
    }
  };

  const defaultValues = {
    text: prompt.prompt,
    is_diff_mode: prompt.is_diff_mode,
  };

  return (
    <TableRowWrapper>
      <CellWrapper className={'question'} cellWidth={cellWidth}>
        {isEditing && (
          <AddPromptModal
            onDismiss={() => setIsEditing(false)}
            isEditMode
            defaultValues={defaultValues}
            favId={favoriteQuestionData?.id || null}
          />
        )}
        <CellActionsWrapper>
          {prompt.block_editting ? null : (
            <Container>
              <IconButton onClick={handleEditClick}>
                <Edit size={17} />
              </IconButton>
            </Container>
          )}
          {showFavButton ? (
            <IconButton onClick={isFavorite ? handleUnFav : handleFav}>
              <Star size={20} filled={isFavorite} />
            </IconButton>
          ) : null}

          <IconButton onClick={isCollapsed ? handleExpand : handleCollapse}>
            {isCollapsed ? <Expand size={19} /> : <Collapse size={20} />}
          </IconButton>
          <Container>
            <CloseButton size={15} onClick={handleDeleteRow} />
          </Container>
        </CellActionsWrapper>
        <Body5>{displayPrompt}</Body5>
      </CellWrapper>
      {(cells || emptyCells).map((a, i) =>
        error || streamError ? (
          <CellWrapper key={`${i}-error`} cellWidth={cellWidth}>
            <Container>
              <Body5>{error || streamError}</Body5>
            </Container>
            <Container top={16}>
              <TransparentFlatButton onClick={onRetry}>Reload</TransparentFlatButton>
            </Container>
          </CellWrapper>
        ) : isCollapsed ? (
          <CellWrapper key={`${i}-collapsed`} cellWidth={cellWidth}></CellWrapper>
        ) : (
          <CellAnswer key={i} answersLookup={answersLookup} index={a?.answer_index} cellWidth={cellWidth} />
        )
      )}
    </TableRowWrapper>
  );
}

function DocHeading({ data: docs, cellWidth }) {
  const onViewOriginalDoc = (doc) => {
    const { document_url, id } = doc;

    if (document_url?.length > 0) {
      window.open(document_url, '_blank');
    } else {
      window.open(ROUTES.docViewer + `?id=${id}`, '_blank');
    }
  };

  return (
    <TableHeadingCellWrapper>
      {docs.map((doc) => (
        <DocLink key={doc.id} onClick={() => onViewOriginalDoc(doc)}>
          <Body5 bold>{doc.filename}</Body5>
        </DocLink>
      ))}
    </TableHeadingCellWrapper>
  );
}

const AddPromptCell = () => {
  const [isAddingPrompt, setIsAddingPrompt] = React.useState(false);
  const onDismiss = () => setIsAddingPrompt(false);

  const handleAddQuestionClick = () => {
    setIsAddingPrompt(true);
  };

  return (
    <>
      {isAddingPrompt && <AddPromptModal onDismiss={onDismiss} />}
      <TransparentFlatButton onClick={handleAddQuestionClick}>+ Add Question</TransparentFlatButton>
    </>
  );
};

export const SeriesTable = ({ tableRows, docColumns, tool, docMode }) => {
  const { width: windowWidth } = useWindowSize();
  const cellCount = docColumns.length;
  const cellWidth = calcCellWidth(windowWidth, cellCount);
  return (
    <Wrapper>
      <TableWrapper>
        <thead>
          <tr>
            <th className={'question-column'}>
              <Body5 bold>Question</Body5>
            </th>
            {docColumns.map((d, i) =>
              d === null ? null : <DocHeading key={i} data={d} index={i} cellWidth={cellWidth} />
            )}
          </tr>
        </thead>
        <tbody>
          {tableRows.map((r, i) => (
            <TableRow key={r.prompt.prompt} index={i} data={r} cellWidth={cellWidth} tool={tool} docMode={docMode} />
          ))}
          <tr>
            <td colSpan={docColumns.length}>
              <AddPromptCell />
            </td>
          </tr>
        </tbody>
      </TableWrapper>
    </Wrapper>
  );
};
