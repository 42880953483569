import styled from 'styled-components';
import { Container, Page } from '../../libs/nvstr-common-ui.es';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import React from 'react';
import { AI_TOOLS, CURRENT_DEFAULT_MODEL } from '../../constants';
import { CompanyQuestionPromptsSummary } from './components/CompanyOverview';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { TickerSelect } from './components/TickerSelect';
import { FormState } from '../../components/UI/FormState';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { ResultRatingForm } from '../form/ResultRatingForm';
import { AdvancedSettingsFields } from '../../hooks/application/useAdvancedSettings';
import { applicationActionTypes } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { AdvancedSettingsButton } from './AdvancedSettings';
import { PROMPT_SERIES_MODES, usePromptSeries } from '../../hooks/features/usePromptSeries';

const PageWrapper = styled.div`
  + div {
    width: 100%;
  }
`;
const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;

const advancedSettingsFields = [
  // AdvancedSettingsFields.seeContext,
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.maxContextLength,
  AdvancedSettingsFields.smoothingToggle,
  AdvancedSettingsFields.includeUserDocsToggle,
  AdvancedSettingsFields.maxContextChunks,
];

const defaultAdvancedSettings = {
  model: CURRENT_DEFAULT_MODEL,
  enableSmoothing: false,
  includeUserDocs: true,
  maxContextLength: '',
  maxAnswerLength: '',
};

const tool = AI_TOOLS.BULL_BEAR_CASES;

export default function BullBearCasesTool({ onGoBack }) {
  const dispatch = useDispatch();

  const [tickers, setTickers] = React.useState([]);
  const [companyName, setCompanyName] = React.useState('');
  const { resultId, resultIdTicker, questionList, formError, onSubmit, onClear } = usePromptSeries(tool);

  React.useEffect(() => {
    if (resultId) {
      const id = 'snapshot-answers-section';
      const yOffset = -26;
      const element = document.getElementById(id);
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        setTimeout(() => {
          window.scrollTo({ top: y, behavior: 'smooth' });
        }, 300);
      }
    }
  }, [resultId]);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  const onGetWarningIndicators = () => {
    onSubmit(tickers[0], PROMPT_SERIES_MODES.bullBear);
  };

  const onAddTicker = (t, name) => {
    setTickers([t]);
    setCompanyName(name);
  };

  const onRemoveTicker = () => {
    setTickers([]);
  };

  const handleClear = () => {
    onClear();
  };

  return (
    <PageWrapper>
      <Page width={resultId ? '1000px' : '800px'}>
        <BackArrowButton onClick={onGoBack} />
        <Container top={40}>
          <FormWrapper>
            <AdvancedSettingsButton fields={advancedSettingsFields} />
            <Container top={24}>
              <TickerSelect
                onAddTicker={onAddTicker}
                onRemoveTicker={onRemoveTicker}
                tickers={tickers}
                multipleTickersEnabled={false}
              />
            </Container>
            <Container top={24}>
              <Container centerAll>
                <FormState error={formError} />
              </Container>
              <SubmitButtonWrapper>
                <FlatButton disabled={tickers.length === 0} onClick={onGetWarningIndicators} fullWidth>
                  Get Bull Bear Cases
                </FlatButton>
              </SubmitButtonWrapper>
              {resultId ? (
                <Container top={8}>
                  <SubmitButtonWrapper>
                    <TransparentFlatButton onClick={handleClear} fullWidth>
                      Clear
                    </TransparentFlatButton>
                  </SubmitButtonWrapper>
                </Container>
              ) : null}
            </Container>
          </FormWrapper>
        </Container>
      </Page>
      {resultId && (
        <Page width={'1000px'}>
          <div id={'snapshot-answers-section'} />
          <CompanyQuestionPromptsSummary
            companyName={companyName}
            ticker={resultIdTicker}
            resultId={resultId}
            questionList={questionList}
            isBullBear
          />
          <ResultRatingForm resultId={resultId} />

          <Container top={24} centerAll>
            <SkeletonButton onClick={handleClear}>Close Section</SkeletonButton>
          </Container>
        </Page>
      )}
    </PageWrapper>
  );
}
