import React from 'react';
import styled from 'styled-components';
import { TickerSelect } from './TickerSelect';
import { DocumentSelection } from '../DocumentSelection';
import { useDocs } from '../../../hooks/features/useDocs';
import { Loading } from '../../../components/UI/Loading';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { filterDuplicates } from '../../../utils/usefulFuncs';

const Wrapper = styled.div``;
const TickerSelectWrapper = styled.div`
  max-width: 600px;
`;
const DocumentSelectionWrapper = styled.div`
  padding: 36px 0 0 0;
`;

export const TickerDocumentSelection = ({ selectedDocs, onSelect, onRemoveSelect, onAction, prevActionCache }) => {
  const [tickers, setTickers] = React.useState(prevActionCache?.tickers || []);

  const onAddTicker = (v) => {
    onAction('ticker', v);
    setTickers(filterDuplicates([...tickers, v]));
  };

  const onRemoveTicker = (v) => {
    setTickers(tickers.filter((t) => t !== v));
  };

  const onReceived = () => null;

  const filters = React.useMemo(() => {
    return { tickers: filterDuplicates(tickers), noUserDocs: true };
  }, [tickers]);

  const { docs, isLoading } = useDocs(filters, onReceived);

  return (
    <Wrapper>
      <TickerSelectWrapper>
        <TickerSelect
          onAddTicker={onAddTicker}
          onRemoveTicker={onRemoveTicker}
          tickers={tickers}
          isResearchMode={false}
          multipleTickersEnabled
        />
      </TickerSelectWrapper>
      <DocumentSelectionWrapper>
        {tickers.length === 0 ? (
          <Container>
            <Body5>
              Enter and select a ticker in the search to see a list of all the available documents for the ticker.
            </Body5>
          </Container>
        ) : isLoading ? (
          <Loading />
        ) : (
          <DocumentSelection
            tickers={tickers}
            docs={docs}
            onSelect={onSelect}
            onRemoveSelect={onRemoveSelect}
            selectedDocs={selectedDocs}
            callWithEntireDoc
          />
        )}
      </DocumentSelectionWrapper>
    </Wrapper>
  );
};
