import React from 'react';
import styled from 'styled-components';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { Modal } from '../../components/layout/Modal';
import { Body1, Body5, Container } from '../../libs/nvstr-common-ui.es';
import { CloseButton } from '../../components/buttons/CloseButton';
import { useDispatch } from 'react-redux';
import { FlatButton } from '../../components/buttons';
import { sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import { Working } from '../../components/UI/Working';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { watchlistActions } from '../../constants/actionTypes';

const Wrapper = styled.div`
  min-width: 600px;

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 300px;
    width: 100%;
    padding: 16px;
  }

  @media (max-width: 800px) {
    width: 350px;
  }
`;

function _removeDuplicates(arr) {
  try {
    const uniqueSet = new Set(arr);
    return [...uniqueSet];
  } catch (e) {
    return arr;
  }
}

export function WatchlistImport() {
  const [modalProps] = React.useState({ maximizeWidth: true, forceHeight: false });

  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isModalShowing, setIsModalShowing] = React.useState(false);

  const [error, setError] = React.useState(null);
  const [value, setValue] = React.useState('');

  const onTextChange = (e) => {
    const v = e.target.value;
    setValue(v);
  };

  const handleImportWatchlistStart = () => {
    TrackingEvent.create('Click Start Import Watchlist');
    setIsModalShowing(true);
  };

  const onSubmit = async () => {
    setError(null);
    setIsModalShowing(false);
    setIsSubmitting(true);
    const tickers = value
      .split(' ')
      .join('')
      .split(',')
      .map((t) => t.toUpperCase());
    TrackingEvent.create('Submit Import Watchlist', {
      Tickers: tickers,
    });

    const failedTickers = await postNewWatchlistStocks(tickers);
    if (failedTickers && failedTickers.length > 0) {
      setError(
        `The following ticker${failedTickers.length > 1 ? 's are' : ' is'} not available at this time: ` +
          failedTickers.join(',')
      );
    }
    setValue('');
    setIsSubmitting(false);
  };

  const getSearchResultsFromAPI = async (v) => {
    let URL = `v1/genai_tickers_search?query=${v}`;
    console.log(URL);
    const { status, data, error } = await sendApiRequest('get', URL);
    logNetRequest(status, data, error);
    if (status !== 200) {
      return null;
    }
    return data;
  };

  const convertTickersToWatchlistItems = async (tickers) => {
    const items = [];
    const failedTickers = [];
    const requests = tickers.map(async (t) => {
      const results = await getSearchResultsFromAPI(t);
      if (!results) {
        failedTickers.push(t);
        return;
      }
      let found = null;
      results.forEach((r) => {
        if (r.symbol === t) {
          found = r;
        }
      });
      if (found !== null) {
        items.push(found);
      } else {
        failedTickers.push(t);
      }
    });
    await Promise.all(requests);
    return {
      items,
      failedTickers,
    };
  };

  const postNewWatchlistStocks = async (tickers) => {
    const { items, failedTickers } = await convertTickersToWatchlistItems(_removeDuplicates(tickers));
    const URL = `v1/genai_watchlist`;
    const form = {
      watchlist: items.map((item) => item.company_id),
    };
    const { status, data, error } = await sendApiRequest('post', URL, form);
    logNetRequest(URL, status, data);
    if (status === 200) {
      dispatch({
        type: watchlistActions.add,
        payload: items,
      });
      return failedTickers;
    } else {
      setError('Something went wrong, please try again.');
      return null;
    }
  };

  return (
    <Wrapper>
      <SkeletonButton onClick={handleImportWatchlistStart}>Import Watchlist CSV</SkeletonButton>

      {error && (
        <Container top={16}>
          <Body5 bold>{error}</Body5>
        </Container>
      )}
      {isSubmitting && (
        <Modal modalProps={modalProps} onDismiss={() => null}>
          <Container>
            <Working />
          </Container>
        </Modal>
      )}

      {isModalShowing && (
        <Modal modalProps={modalProps} onDismiss={() => setIsModalShowing(false)}>
          <Container>
            <Container row spaceBetween verticallyCenter>
              <Body1 bold>Enter Ticker/Symbol CSV</Body1>
              <CloseButton onClick={() => setIsModalShowing(false)} />
            </Container>
            <Container top={24}>
              <textarea value={value} onChange={onTextChange} placeholder={'Enter here, ie. (AAPL,MSFT,...)'} />
              <Container top={24} style={{ textAlign: 'center' }}>
                <FlatButton onClick={onSubmit} fullWidth>
                  Submit
                </FlatButton>
              </Container>
            </Container>
          </Container>
        </Modal>
      )}
    </Wrapper>
  );
}
