import React from 'react';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import {
  applyFilters,
  buildChartData,
  buildMetadata,
  format,
  parseCSV,
  sortTranscriptStatementOrder,
} from '../../utils/dataFormatters/sentiment';
import { logNetRequest } from '../../utils/usefulFuncs';

function generateDocumentTranscriptLookup(transcriptData) {
  const lookup = {};
  transcriptData.forEach((line) => {
    const { fid } = line;
    if (!lookup[fid]) {
      lookup[fid] = { lines: [] };
    }
    lookup[fid].lines.push(line);
  });
  const documentTranscriptLines = Object.keys(lookup);
  console.log(documentTranscriptLines.length + ' documents', documentTranscriptLines);
  documentTranscriptLines.forEach((key) => {
    const groupedLinesBySpeaker = [];
    const lines = lookup[key].lines;
    let currentSpeaker = lines[0].speaker;
    lines.forEach((line, i) => {
      const { speaker } = line;
      if (i === 0) {
        groupedLinesBySpeaker.push([line]);
      } else {
        if (currentSpeaker === speaker) {
          groupedLinesBySpeaker[groupedLinesBySpeaker.length - 1].push(line);
        } else {
          groupedLinesBySpeaker.push([line]);
        }
      }
    });
    lookup[key].groupedLinesBySpeaker = groupedLinesBySpeaker;
  });
  return lookup;
}

export const useSentimentAnalysis = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [rawCSV, setRawCSV] = React.useState(null);

  const [documentTranscriptLookup, setDocumentTranscriptLookup] = React.useState(null);
  const [chartData, setChartData] = React.useState(null);
  const [metadata, setMetaData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const onClear = React.useCallback(async () => {
    setIsLoading(false);
    setData(null);
    setRawCSV(null);
    setDocumentTranscriptLookup(null);
    setChartData(null);
    setMetaData(null);
    setError(null);
  }, []);

  const onSubmit = React.useCallback(async (doc) => {
    if (!doc) return;

    const { record_id: id, doc_ids } = doc;
    const docId = id;
    setIsLoading(true);
    setError(null);

    const onlyFed = 'true';
    const URL = `v1/genai_fed_sentiment_get?record_ids=${docId}&doc_ids=${doc_ids}&only_fed_speakers=${onlyFed}`;
    const { status, data, error } = await sendApiRequest('get', URL);
    handle400Statuses(status);
    logNetRequest(
      `v1/genai_fed_sentiment_get?record_ids=${docId}&doc_ids=${doc_ids}&only_fed_speakers=${onlyFed}`,
      status,
      data
    );

    if (status === 200) {
      const { csv_string, document_metadata } = data;
      setRawCSV(csv_string);
      const parsedCsv = await parseCSV(csv_string);
      const rawTranscriptData = sortTranscriptStatementOrder(parsedCsv);
      const transcriptData = applyFilters(rawTranscriptData);
      const d = format(transcriptData);
      const documentTranscriptLookup = generateDocumentTranscriptLookup(transcriptData);
      setDocumentTranscriptLookup(documentTranscriptLookup);

      setData(d);
      const cd = buildChartData(transcriptData);
      setChartData(cd);
      const md = buildMetadata(rawTranscriptData, data);
      setMetaData(md);
      setIsLoading(false);
    } else {
      setError(data?.error || error || 'Something went wrong, try again.');
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    data,
    rawCSV,
    documentTranscriptLookup,
    chartData,
    metadata,
    error,
    onSubmit,
    onClear,
  };
};
